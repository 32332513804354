@use "../../lib/themes/theme.scss" as theme;
.Select input::-ms-clear {
  display: none !important;
}
.Select input::-ms-reveal {
  display: none !important;
}

.Select.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: darkgray !important;
}
.Select.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent darkgray !important;
  border-width: 0 5px 5px;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
  box-shadow: inset 0 1px 1px transparent, 0 0 0 3px transparent !important;
  background: #fff;
}

.Select.has-value.Select--single
> .Select-control
.Select-value
a.Select-value-label:hover,
.Select.has-value.is-pseudo-focused.Select--single
> .Select-control
.Select-value
a.Select-value-label:hover,
.Select.has-value.Select--single
> .Select-control
.Select-value
a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
> .Select-control
.Select-value
a.Select-value-label:focus {
  color: yellow !important;
  outline: none;
  text-decoration: underline;
}

.Select-control:hover {
  box-shadow: 0 0px 0 lightgray !important;
}

.Select-option.is-focused {
  // background-color: rgba(255, 220, 74, 1) !important;
  color: #333;
}

.Select--multi .Select-value {
  background-color: rgb(220, 220, 220) !important;
  /* Fallback color for IE 8 */
  border-radius: 20px;
  /* Fallback color for IE 8 */
  border: 0px solid darkgray !important;
  color: black;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
  padding: 5px;
  padding-bottom: 3px;
}

.Select--multi a.Select-value-label {
  color: darkgray !important;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-radius: 20px;
  border-right: 0px solid darkgray !important;
  /* Fallback color for IE 8 */
  padding: 1px 5px 3px;
  color: black;
}
.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  // background-color: yellow !important;
  /* Fallback color for IE 8 */
  background-color: lightgray !important;
  color: white;
}
.Select--multi .Select-value-icon:active {
  background-color: white !important;
  /* Fallback color for IE 8 */
  background-color: lightgray !important;
}

.Select--multi.Select--rtl .Select-value-icon {
  border-right: none;
  border-left: 1px solid darkgray !important;
}
.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid darkgray !important;
  color: #333;
}
.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid darkgray !important;
}
.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: lightgray !important;
}

.Select-control .Select-multi-value-wrapper .Select-placeholder {
  text-indent: 15px;
}

.root {
}

.appBar {
  position: relative;
  padding-right: 0px !important;
  box-shadow: none;
}
.toolbar {
  padding-right: 8px;
}
.appBarButton {
  margin: 0 16px 0 8px;
}
.stepperWrapper {
  display: flex;
  background-color: #fff;
  height: 64px;
}
.stepper {
  width: 750px;
  margin: auto;
  vertical-align: middle;
}
.subheader {
  margin-top: 24px;
}
.content.content {
  display: flex;
  padding: 0px;

  width: 100%;
  padding-top: 128px;
  min-height: 500px;
  overflow-y: scroll;
}
.formWrapper {
  margin-left: 164px;
  margin-right: 164px;
  width: 100%;
  padding-bottom: 64px;
}
.cardWrapper {
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
}
.inputElement.inputElement {
  margin: 24px;
}
.responseFrom {
  color: #00000050;
  margin-bottom: 48px;
}

.stepFooter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 48px;
}
.divider {
  margin-top: 24px;
  margin-bottom: 16px;
  flex: 1 0 100%;
}
.divider2 {
  margin-top: 32px;
  margin-bottom: 0px;
  flex: 3 0 100%;
}
.divider3 {
  margin-top: 16px;
  margin-bottom: 0;
  flex: 1 0 100%;
}
.radioTitle {
  width: calc(50% - 24px);
  margin-right: 24px;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  padding: 8px;
}
.datePickerControl {
  overflow: hidden;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  transition: width 150ms ease-in;
  margin-top: 0px;
  margin-bottom: 0px;
}
.datePickerVisible {
  width: 100%;
}
.stepButton {
  margin-left: 16px;
}
.step {
  padding-top: 24px;
  max-width: 500px;
  margin: auto;
}
.padBottom {
  padding-bottom: 32px;
}
.flex {
  flex: 1px;
}
.chip {
  display: inline-flex;
  padding: 0px 0px 0 0;
  margin-left: 5px;
}
.selectedChip {
  background-color: theme.$primary;
  color: rgb(51, 59, 69);
  &:focus {
    background-color: theme.$primary;
    color: rgb(51, 59, 69);
  }
}
.halfWidth {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.item {
  margin-bottom: 16px;
}
.employeeList {
  padding-left: 0px;
  margin-top: 0px;
}
.employeeListItem {
  list-style: none;
}
.menuItem {
  height: auto;
  display: flex;
}
.toolboxInput {
  height: auto;
}
.sectorInput {
  height: auto;
  display: initial;
}
.selectInput {
  & > div > div {
    min-height: 64px;
    padding: 8px 16px 0 16px;

    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.finalTitle {
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  text-align: left;
  line-height: 24px;
}
.finalBox {
  background-color: #FFFFFF;
  padding: 16px;
  margin-bottom: 8px;
}
.topicInput {
  background-color: rgba(220,220, 220, 0.3);
  height: 40px;
  padding-left: 25px;
  padding-top: 10px;
}
.toolboxIcon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.photoThumb {
  &:hover {
  cursor: nesw-resize;
  }
  max-width: 128px;
  max-height: 128px;
  margin-top: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}
.photoLarge {
  &:hover {
    cursor: nesw-resize;
  }
  max-width: 512px;
  max-height: 512px;
  margin-top: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}
.responseCard {

}
.responseCardContent {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.wrapContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}