.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: 1102;
}
.item.item {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 24px 0 24px 0;
}
.item2.item2 {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 24px 0 0 48px;
}
.item3.item3 {
  display: inline-block;
  width: calc(50% - 24px);
  margin: 32px 0 0 48px;
}
.radioTitle {
  margin-left: 24px;
  width: calc(50% - 24px);
  white-space: nowrap;
  vertical-align: middle;
}
.datePickerControl {
  overflow: hidden;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  transition: width 150ms ease-in;
  margin-top: 24px;
  margin-bottom: 0px;
}
.datePickerVisible {
  width: 50%;
}
