.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: 1102;
}
.appModule {
  margin-top: 0px;
}
.taskNotFinished {
  background-color: #CFCFCF;
  height: 16px;
  padding: 16px;
}

.taskFinished {
  background-color: #47CF21;
  padding: 16px;
  height: 16px;
}

