$primary: #FFDC4A !default;
$secondary: #272D35 !default;
$highlight: #FFDC4A !default;
$accent: #4ADCDC !default;
$highlightText: #000000 !default;
$text: #5E646D !default;
$textDark: #272D35 !default;
$error: red !default;
$green: green !default;
$border: #ECF1F4 !default;
$zindex: 1101 !default;
$selectGrey: #eee !default;
$faintBlack: #272D35 !default;
$lightGreyBackground: #f5f5f5 !default;
$spacing: 8px !default;

.drawerButtonColor.drawerButtonColor{
  color:$secondary;
}
.secondaryButton.secondaryButton {
  color: $secondary;
  &:hover {
    background-color: $selectGrey;
  }
}

:export {
  primaryColor: $primary;
  secondaryColor: $secondary;
  faintBlackColor: $faintBlack;
  highlightTextColor: $highlightText;
  whiteTextColor: $lightGreyBackground;
  greyTextColor: $text;
  mainTabsColor: $text;
  mainTabsSelectedColor:  $highlightText;
}